import { t } from "./locale";
import { currentLanguage } from "@telia/b2b-i18n";
import { corpInstalledbaseVpn } from "@telia/b2b-rest-client";

type OrganisationUI = {
  name?: string;
  number?: string;
};

export interface Data {
  encryption?: boolean;
  multicast?: boolean;
  organisation?: OrganisationUI;
  uId?: string;
  vpnAlias?: string;
  vpnId?: string;
  vpnName?: string;
}

export type CustomerAliasRequest = {
  customerAlias?: string;
};

type SortingColumnName = "id" | "name" | "alias" | "organisationName" | "encryption" | "multicast";
type SortDirection = "ORIGIN" | "ASC" | "DSC";

interface ColumnSort {
  column: SortingColumnName;
  sortDirection: SortDirection;
}

function getSortIcon(sort: SortDirection) {
  switch (sort) {
    case "ASC":
      return "arrow-up";
    case "DSC":
      return "arrow-down";
    default:
      return "sorter";
  }
}

function tableColumns(columnsSort?: ColumnSort) {
  return [
    {
      title: "id",
      type: "text",
      isChecked: true,
      disabled: false,
      sortable: {
        sortIconName:
          columnsSort?.column === "id" ? getSortIcon(columnsSort.sortDirection) : "sorter",
      },
      filterable: { type: "text", label: t("connectedVpns.id") },
    },
    {
      title: "name",
      type: "text",
      isChecked: true,
      disabled: false,
      sortable: {
        sortIconName:
          columnsSort?.column === "name" ? getSortIcon(columnsSort.sortDirection) : "sorter",
      },
      filterable: { type: "text", label: t("connectedVpns.name") },
    },
    {
      title: "alias",
      type: "text",
      isChecked: true,
      disabled: false,
      sortable: {
        sortIconName:
          columnsSort?.column === "alias" ? getSortIcon(columnsSort.sortDirection) : "sorter",
      },
      filterable: { type: "text", label: t("connectedVpns.alias") },
    },
    {
      title: "organisationName",
      type: "text",
      isChecked: true,
      disabled: false,
      sortable: {
        sortIconName:
          columnsSort?.column === "organisationName"
            ? getSortIcon(columnsSort.sortDirection)
            : "sorter",
      },
      filterable: { type: "text", label: t("connectedVpns.organisationName") },
    },
    {
      title: "encryption",
      type: "status-badge",
      isChecked: true,
      disabled: false,
      sortable: {
        sortIconName:
          columnsSort?.column === "encryption" ? getSortIcon(columnsSort.sortDirection) : "sorter",
      },
    },
    {
      title: "multicast",
      type: "status-badge",
      isChecked: true,
      disabled: false,
      sortable: {
        sortIconName:
          columnsSort?.column === "multicast" ? getSortIcon(columnsSort.sortDirection) : "sorter",
      },
    },
  ];
}

export async function getVpns(scopeId: string, userId: string) {
  const vpns = (
    await corpInstalledbaseVpn.VpnControllerService.getVpnsUsingGet(scopeId, userId)
  ).map((data) => mapData(data));
  return vpns;
}

export async function getVpnsForSubscription(scopeId: string, capability: string) {
  const vpns = (
    await corpInstalledbaseVpn.VpnControllerService.getVpnsOnSubscriptionUsingGet(
      scopeId,
      capability
    )
  ).map((data) => mapData(data));
  return vpns;
}

export async function saveVpnAlias(
  customerAlias: CustomerAliasRequest,
  scopeId: string,
  userId: string
) {
  await corpInstalledbaseVpn.VpnControllerService.putVpnAliasUsingPut(
    customerAlias,
    scopeId,
    userId
  );
}

const mapData = (data: Data) => [
  data.vpnId,
  data.vpnName,
  data.vpnAlias,
  data.organisation?.name,
  data.encryption
    ? { content: t("connectedVpns.yes"), variant: "positive" }
    : { content: t("connectedVpns.no"), variant: "warning" },
  data.multicast
    ? { content: t("connectedVpns.yes"), variant: "positive" }
    : { content: t("connectedVpns.no"), variant: "warning" },
  data.uId,
];

export function createTableColumn(columnSort: ColumnSort) {
  return tableColumns(columnSort).map((c) => ({
    ...c,
    title: getTranslateTableColumnTitle(c.title),
  }));
}

export function getColumnTitle(title: string): string | undefined {
  return tableColumns().find((c) => getTranslateTableColumnTitle(c.title) === title)?.title;
}

function getTranslateTableColumnTitle(title: string) {
  return t(`connectedVpns.${title}`);
}

function getColumnIndex(title: string): number {
  return tableColumns().findIndex((c) => c.title === title);
}

export function searchRows(filteredRows: any[], columnFilter: { [x: string]: any }) {
  Object.keys(columnFilter).forEach((columnKey) => {
    filteredRows = filteredRows.filter((row) =>
      row[getColumnIndex(columnKey)].toLowerCase().includes(columnFilter[columnKey].toLowerCase())
    );
  });
  return filteredRows;
}

export function sortRows(
  filteredRows: any[],
  selectedSorting: string,
  sorting: { [x: string]: string }
) {
  const columnIndex = getColumnIndex(selectedSorting);
  filteredRows.sort((a, b) => {
    if (typeof a[columnIndex] === "object" && a[columnIndex] !== null) {
      return a[columnIndex].content.localeCompare(b[columnIndex].content, currentLanguage(), {
        sensitivity: "base",
      });
    } else {
      return a[columnIndex].localeCompare(b[columnIndex], currentLanguage(), {
        sensitivity: "base",
      });
    }
  });

  if (sorting[selectedSorting] === "ASC") {
    return filteredRows.reverse();
  }
  return filteredRows;
}
